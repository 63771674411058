// noinspection JSUnusedGlobalSymbols
export const MAPPING_BANK= (gql: any) => gql`
    query mapping {
        mappingBanks {
                properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_PAYMENT= (gql: any) => gql`
    query mapping {
        mappingPayments {
                properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_TRANSACTION= (gql: any) => gql`
    query mapping {
        mappingTransactions {
                properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_RECEIPT= (gql: any) => gql`
    query mapping {
        mappingReceipts {
                properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_MATCH= (gql: any) => gql`
    query mapping {
        mappingMatchs {
                properties
        }
    }
`;
export const MAPPING_PAYLOAD = (gql: any) => gql`
    query mapping {
        mappingPayloads {
                properties
        }
    }
`;

export const MAPPING_USERS= (gql: any) => gql`
    query mapping {
        mappingUsers {
                properties
        }
    }
`;

export const MAPPING_STORE= (gql: any) => gql`
    query mapping {
        mappingStores {
            properties
        }
    }
`;

export const MAPPING_ORGANIZATION= (gql: any) => gql`
    query mapping {
        mappingOrganizations {
                properties
        }
    }
`;

export const MAPPING_MERCHANT= (gql: any) => gql`
    query mapping {
        mappingMerchants {
                properties
        }
    }
`;
export const MAPPING_CONSUMER= (gql: any) => gql`
    query mapping {
        mappingConsumers {
                properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const CREATE_BANK= (gql: any) => gql`
    mutation createBank($data: CreateBankInput!) {
        createBank(data: $data) {
            id
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const CREATE_PASSWORD_RECOVERY= (gql: any) => gql`
    mutation createPasswordRecovery($data: CreatePasswordRecoveryInput!) {
        createPasswordRecovery(data: $data) {
            id
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const TOKEN_DECODE= (gql: any) => gql`
    query tokenDecode($token: String!) {
        tokenDecode(token: $token) {
            valid
            remainingSeconds
            userId
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const UPDATE_PASSWORD_WITH_TOKEN= (gql: any) => gql`
    mutation updatePasswordWithToken($token: String!, $password: String!) {
        updatePasswordWithToken(token: $token, password: $password) {
            updated
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const DELETE_BANK = (gql: any) => gql`
    mutation deleteBank($id: ID!) {
        deleteBank(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_BANKS = (gql: any) => gql`
    query findBanks($offset: String, $limit: Int) {
        findBanks(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                createdAt
                updatedAt
                status
                organization
                firstBankingNetwork
                secondBankingNetwork
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_BANKS = (gql: any) => gql`
    query searchBanks($offset: String, $limit: Int, $sort: String) {
        searchBanks(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                createdAt
                updatedAt
                status
                organization
                firstBankingNetwork
                secondBankingNetwork
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_BANK = (gql: any) => gql`
    query getBank($id: ID!) {
        getBank(id: $id) {
            id
            name
            hmac
            createdAt
            updatedAt
            status
            tags
            organization
            statMatches
            statConsumers
            statTransactions
            firstBankingNetwork
            secondBankingNetwork
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_MATCH_CLIENT = (gql: any) => gql`
    query getMatchInBankFormat($matchId: ID!) {
        getMatchInBankFormat(matchId: $matchId) {
            data
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_PAYMENT = (gql: any) => gql`
    query getPayment($id: ID!) {
        getPayment(id: $id) {
            id
            amount
            reference
            degradedReference
            date
            matchable
            storeName
            receiptId
            status
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_BANK = (gql: any) => gql`
    mutation updateBank($id: ID!, $data: UpdateBankInput!) {
        updateBank(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_CONSUMER = (gql: any) => gql`
    mutation createConsumer($data: CreateConsumerInput!) {
        createConsumer(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_CONSUMER = (gql: any) => gql`
    mutation deleteConsumer($id: ID!) {
        deleteConsumer(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_CONSUMERS = (gql: any) => gql`
    query findConsumers($offset: String, $limit: Int) {
        findConsumers(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                createdAt
                updatedAt
                status
                bankName
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_CONSUMERS = (gql: any) => gql`
    query searchConsumers($offset: String, $limit: Int, $sort: String) {
        searchConsumers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                createdAt
                updatedAt
                status
                bankName
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_BANK_CONSUMERS = (gql: any) => gql`
    query searchBankConsumers($id: String!, $offset: String, $query: SearchQueryInput, $limit: Int, $sort: String) {
        searchBankConsumers(id: $id, offset: $offset, query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                createdAt
                updatedAt
                status
                bankName
            }
        }
    }
`;

//noinspection JSUnusedGlobalSymbols
export const FIND_CONSUMERS_BY_BANK = (gql: any) => gql`
    query findConsumersByBank($id: ID!) {
        findConsumersByBank(bankId: $id) {
            items {
                id
                name
                createdAt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_CONSUMER = (gql: any) => gql`
    query getConsumer($id: ID!) {
        getConsumer(id: $id) {
            id
            name
            createdAt
            updatedAt
            status
            tags
            bankName
            statTransactions
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_CONSUMER = (gql: any) => gql`
    mutation updateConsumer($id: ID!, $data: UpdateConsumerInput!) {
        updateConsumer(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_CURRENT_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_PARTNER_ACCESS = (gql: any) => gql`
    query findPartnerAccess{
        findPartnerAccess{
            items{ id partnerName accessInfo}
        }
    }
`;


// noinspection JSUnusedGlobalSymbols
export const DELETE_PARTNER_ACCESS = (gql: any) => gql`
    mutation deletePartnerAccess($id: ID!) {
        deletePartnerAccess(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_PARTNER_ACCESS = (gql: any) => gql`
    mutation createPartnerAccess($data: PartnerAccessInput!) {
        createPartnerAccess(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_CURRENT_USER = (gql: any) => gql`
mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
        id
    }
}
`;

export const CREATE_CURRENT_USER_KEY = (gql: any) => gql`
    mutation createCurrentUserKey($data: CreateKeyInput!){
        createCurrentUserKey(data: $data){
            id
        }
    }
`;

export const FIND_CURRENT_USER_KEY = (gql: any) => gql`
    query {
        findCurrentUserKey {
            items {
                id
                userMail
                expirationDate
                permissions {
                    actions
                    ressource
                }
            }
        }
    }
`;
export const FIND_CURRENT_BANK_PAYLOADS = (gql: any) => gql`
        query {
            findCurrentBankPayloads {
                count
                cursor
                items {
                    id
                    organizationId
                    payloadType
                    restaurantId
                    partner
                    payload
                    date
                    status
                    receiptId
                    transactionId
                    externalReference
                    report
                }
            }
        }
    
`;
// noinspection JSUnusedGlobalSymbols
export const FIND_ITEMS = (gql: any) => gql`
    query findItems($offset: String, $limit: Int) {
        findItems(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_ITEM = (gql: any) => gql`
    query getItem($id: ID!) {
        getItem(id: $id) {
            id
            createdAt
            updatedAt
            status
            tags
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const LOGIN = (gql: any) => gql`
    mutation login($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_MATCH = (gql: any) => gql`
    mutation deleteMatch($id: ID!) {
        deleteMatch(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_MATCHES = (gql: any) => gql`
    query findMatches($offset: String, $limit: Int) {
        findMatches(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                reference
                bankName
                bank
                merchant
                merchantName
                storeName
                createdAt
                updatedAt
                status
                amount
                transaction
                receipt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_MATCHES = (gql: any) => gql`
    query searchMatches($offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchMatches(offset: $offset, query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                reference
                bankName
                bank
                merchant
                merchantName
                storeName
                createdAt
                updatedAt
                status
                amount
                transaction
                receipt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_BANK_MATCHES = (gql: any) => gql`
    query searchBankMatches($id: String!, $offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchBankMatches(id: $id, offset: $offset, query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                reference
                bankName
                bank
                merchant
                merchantName
                storeName
                createdAt
                updatedAt
                status
                amount
                transaction
                receipt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_STORE_MATCHES = (gql: any) => gql`
    query searchStoreMatches($id: String!, $offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchStoreMatches(id: $id, offset: $offset, query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                reference
                bankName
                bank
                merchant
                merchantName
                storeName
                createdAt
                updatedAt
                status
                amount
                transaction
                receipt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
// DO NOT DELETE this is being used by search wizard :)
export const SEARCH_MATCHS = (gql: any) => gql`
    query searchMatches($offset: String, $query: SearchQueryInput,$limit: Int, $sort: String) {
        searchMatches(offset: $offset,query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                reference
                bankName
                bank
                merchant
                merchantName
                storeName
                createdAt
                updatedAt
                status
                amount
                transaction
                receipt
            }
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_MATCH = (gql: any) => gql`
    query getMatch($id: ID!) {
        getMatch(id: $id) {
            id
            amount
            createdAt
            updatedAt
            reference
            degradedReference
            bank
            bankName
            merchant
            merchantName
            payment
            receipt
            status
            store
            StoreLegitNumber
            storeName
            tags
            transaction
            transactionConsumer
            transactionExtRef
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_MEMBERSHIP = (gql: any) => gql`
    mutation createMembership($data: CreateMembershipInput!) {
        createMembership(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_MEMBERSHIP = (gql: any) => gql`
    mutation deleteMembership($id: ID!) {
        deleteMembership(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_MEMBERSHIPS = (gql: any) => gql`
    query findMemberships($offset: String, $limit: Int) {
        findMemberships(offset: $offset, limit: $limit) {
            items {
                id
                user
                userEmail
                userFirstName
                userLastName
                organization
                organizationName
                role
                type
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_MEMBERSHIP = (gql: any) => gql`
    query getMembership($id: ID!) {
        getMembership(id: $id) {
            id
            user
            userEmail
            userFirstName
            userLastName
            organization
            organizationName
            role
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_MEMBERSHIP = (gql: any) => gql`
    mutation updateMembership($id: ID!, $data: UpdateMembershipInput!) {
        updateMembership(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_MERCHANT = (gql: any) => gql`
    mutation createMerchant($data: CreateMerchantInput!) {
        createMerchant(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_MERCHANT = (gql: any) => gql`
    mutation deleteMerchant($id: ID!) {
        deleteMerchant(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_MERCHANTS = (gql: any) => gql`
    query findMerchants($offset: String, $limit: Int) {
        findMerchants(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                createdAt
                updatedAt
                status
                statStores
                statReceipts
                statMatches
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_MERCHANTS = (gql: any) => gql`
    query searchMerchants($offset: String, $limit: Int, $sort: String) {
        searchMerchants(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                createdAt
                updatedAt
                status
                statStores
                statReceipts
                statMatches
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_MERCHANT = (gql: any) => gql`
    query getMerchant($id: ID!) {
        getMerchant(id: $id) {
            id
            name
            hmac
            createdAt
            updatedAt
            status
            tags
            statStores
            statReceipts
            statMatches
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_MERCHANT = (gql: any) => gql`
    mutation updateMerchant($id: ID!, $data: UpdateMerchantInput!) {
        updateMerchant(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_ORGANIZATION = (gql: any) => gql`
    mutation createOrganization($data: CreateOrganizationInput!) {
        createOrganization(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_ORGANIZATION = (gql: any) => gql`
    mutation deleteOrganization($id: ID!) {
        deleteOrganization(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_ORGANIZATIONS = (gql: any) => gql`
    query findOrganizations($offset: String, $limit: Int) {
        findOrganizations(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                code
                name
                createdAt
                updatedAt
                status
                owner
                members {
                    items {
                        user
                        userEmail
                        userFirstName
                        userLastName
                        role
                    }
                }
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_ORGANIZATIONS = (gql: any) => gql`
    query searchOrganizations($offset: String, $limit: Int, $sort: String) {
        searchOrganizations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                createdAt
                updatedAt
                status
                owner
                members {
                    items {
                        user
                        userEmail
                        userFirstName
                        userLastName
                        role
                    }
                }
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_ORGANIZATION = (gql: any) => gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            id
            code
            name
            type
            createdAt
            updatedAt
            status
            tags
            owner
            members {
                items {
                    user
                    userEmail
                    userFirstName
                    userLastName
                    role
                }
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_ORGANIZATION = (gql: any) => gql`
    mutation updateOrganization($id: ID!, $data: UpdateOrganizationInput!) {
        updateOrganization(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_PLATFORM = (gql: any) => gql`
    mutation createPlatform($data: CreatePlatformInput!) {
        createPlatform(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_PLATFORM = (gql: any) => gql`
    mutation deletePlatform($id: ID!) {
        deletePlatform(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_PLATFORMS = (gql: any) => gql`
    query findPlatforms($offset: String, $limit: Int) {
        findPlatforms(offset: $offset, limit: $limit) {
            items {
                id
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_PLATFORM = (gql: any) => gql`
    query getPlatform($id: ID!) {
        getPlatform(id: $id) {
            id
            name
            createdAt
            updatedAt
            status
            tags
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_PLATFORM = (gql: any) => gql`
    mutation updatePlatform($id: ID!, $data: UpdatePlatformInput!) {
        updatePlatform(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_RECEIPT = (gql: any) => gql`
    mutation deleteReceipt($id: ID!) {
        deleteReceipt(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_RECEIPTS = (gql: any) => gql`
    query findReceipts($offset: String, $limit: Int) {
        findReceipts(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                covers
                payments{lastFour, bin, scheme}
                items{taxAmount, taxDescription}
                taxAmount
                taxDescription
                currency
                match
                amount
                storeName
                merchantName
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_DEMOBANKS = (gql: any) => gql`
    query findReceipts($offset: String, $limit: Int) {
        findReceipts(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                covers
                payments{lastFour, bin, scheme}
                items{taxAmount, taxDescription}
                taxAmount
                taxDescription
                currency
                match
                amount
                storeName
                merchantName
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_DEMOBANKS = (gql: any) => gql`
    query searchReceipts($offset: String, $query: SearchQueryInput) {
        searchReceipts(offset: $offset, query: $query, limit: 20, sort: "createdAt:desc") {
            cursor
            count
            items {
                id
                currency
                amount
                storeName
                merchantName
                date
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_DEMOBANK = (gql: any) => gql`
    query getReceipt($id: ID!) {
        getReceipt(id: $id) {
            amount
            archivedAt
            authCode
            id
            createdAt
            covers
            currency
            date
            degradedReference
            externalReference
            hashedExternalReference
            invoice
            qrCode
            items {
                amount
                createdAt
                currency
                description
                discount
                id
                itemName
                parent
                quantity
                status
                subItems {
                    amount
                    createdAt
                    currency
                    description
                    discount
                    id
                    itemName
                    parent
                    quantity
                    status
                    tags
                    taxAmount,
                    taxDescription
                    taxRate
                    type
                    updatedAt
                }
                tags
                taxAmount,
                taxDescription
                taxRate
                type
                updatedAt
            }
            match
            matchable
            matchedAt
            merchant
            merchantName
            mode
            partnerName
            payloadId
            payments {
                amount,
                authCode,
                lastFour,
                bin,
                matchable,
                paymentName,
                scheme,
                transactionDate,
                transactionExternalId,
                transactionId,
            }
            reference
            saleMethod
            scheme
            status
            store
            storeAddress
            storeLegitNumber
            storeName
            table
            tags
            taxAmount
            taxDescription
            taxes {
                taxAmount,
                taxDescription
                taxRate
            }
            totalDiscount
            updatedAt
            kb_features
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_DEMOBANK = (gql: any) => gql`
    mutation deleteReceipt($id: ID!) {
        deleteReceipt(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_RECEIPTS = (gql: any) => gql`
    query searchReceipts($offset: String, $query: SearchQueryInput,$limit: Int, $sort: String) {
        searchReceipts(offset: $offset, query: $query,limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                covers
                payments{lastFour, bin, scheme}
                items{taxAmount, taxDescription}
                taxAmount
                taxDescription
                currency
                match
                amount
                storeName
                merchantName
                partnerName
                date
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_STORE_RECEIPTS = (gql: any) => gql`
    query searchStoreReceipts($id: String!, $offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchStoreReceipts(id: $id, offset: $offset, query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                covers
                payments{lastFour, bin, scheme}
                items{taxAmount, taxDescription}
                taxAmount
                taxDescription
                currency
                match
                amount
                storeName
                merchantName
                createdAt
                updatedAt
                status
            }
        }
    }
`;

//noinspection JSUnusedGlobalSymbols
export const FIND_RECEIPTS_BY_STORE = (gql: any) => gql`
    query findReceiptsByStore($id: ID!, $limit: Int) {
        findReceiptsByStore(storeId: $id, limit: $limit) {
            items {
                id
                createdAt
                updatedAt
                amount
                qrCode
            }
        }
    }
`;

//noinspection JSUnusedGlobalSymbols
export const FIND_MATCHES_BY_STORE = (gql: any) => gql`
    query findMatchesByStore($id: ID!) {
        findMatchesByStore(storeId: $id) {
            items {
                id
                createdAt
                updatedAt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_HISTOGRAM_RECEIPTS = (gql: any) => gql`
    query histogramReceipts{
        histogramReceipts{
            buckets
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_HISTOGRAM_TRANSACTIONS = (gql: any) => gql`
    query histogramTransactions{
        histogramTransactions{
            buckets
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_RECEIPT = (gql: any) => gql`
    query getReceipt($id: ID!) {
        getReceipt(id: $id) {
            amount
            archivedAt
            authCode
            id
            createdAt
            covers
            currency
            date
            degradedReference
            externalReference
            hashedExternalReference
            invoice
            qrCode
            items {
                amount
                createdAt
                currency
                description
                discount
                id
                itemName
                parent
                quantity
                status
                subItems {
                    amount
                    createdAt
                    currency
                    description
                    discount
                    id
                    itemName
                    parent
                    quantity
                    status
                    tags
                    taxAmount,
                    taxDescription
                    taxRate
                    type
                    updatedAt
                }
                tags
                taxAmount, 
                taxDescription
                taxRate
                type
                updatedAt
            }
            match
            matchable
            matchedAt
            merchant
            merchantName
            mode
            partnerName
            payloadId
            payments {
                amount,
                authCode,
                lastFour, 
                bin,
                matchable
                paymentName,
                scheme,
                transactionDate,
                transactionExternalId,
                transactionId
            }
            reference
            saleMethod
            scheme
            status
            store
            storeAddress
            storeLegitNumber
            storeName
            table
            tags
            taxAmount
            taxDescription
            taxes {
                taxAmount, 
                taxDescription
                taxRate
            }
            totalDiscount
            updatedAt
            kb_features
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_RECEIPT = (gql: any) => gql`
    mutation updateReceipt($id: ID!, $data: UpdateReceiptInput!) {
        updateReceipt(id: $id, data: $data) {
            id
        }
    }
`;

/**
 * Query to build HomeScreen
 * @param gql
 * @constructor
 */

// noinspection JSUnusedGlobalSymbols
export const MAKE_STATS = (gql: any) => gql`
    query compound($dayOne: Float!, $today: Float!, $dayTwo: Float!) {
        dashboard(dayOne:$dayOne today:$today dayTwo:$dayTwo )
    }
`;
// noinspection JSUnusedGlobalSymbols
export const REFETCH_PARTNER_DATA = (gql: any) => gql`
    query refetchPartnerData($offset: String, $limit: Int)  {
        findUsers(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                role
            }
        }
        findOrganizations(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                code
                name
                createdAt
                updatedAt
                status
                owner
                members {
                    items {
                        user
                        userEmail
                        userFirstName
                        userLastName
                        role
                    }
                }
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_STORE = (gql: any) => gql`
    mutation createStore($data: CreateStoreInput!) {
        createStore(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_STORE = (gql: any) => gql`
    mutation deleteStore($id: ID!) {
        deleteStore(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_STORES = (gql: any) => gql`
    query findStores($offset: String, $limit: Int) {
        findStores(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                merchant
                externalReference
                paymentName
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
                public
            }
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const FIND_MISSINGDATASSTORES = (gql: any) => gql`
    query findMissingDatasStores($offset: String, $limit: Int ,$condition: JSON) {
        findMissingDatasStores(offset: $offset, limit: $limit, condition: $condition) {
            cursor
            items {
                id
                name
                merchant
                merchantName
                externalReference
                paymentName
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
                public
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_STORES = (gql: any) => gql`
    query searchStores($offset: String, $limit: Int, $query: SearchQueryInput, $sort: String) {
        searchStores(offset: $offset, limit: $limit, query: $query, sort: $sort) {
            cursor
            count
            items {
                id
                name
                merchant
                externalReference
                paymentName
                statReceipts
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
                partnerName
            }
        }
    }
`;

export const SEARCH_PAYMENTS = (gql: any) => gql`
    query searchPayments($offset: String, $query: SearchQueryInput,$limit: Int, $sort: String) {
        searchPayments(offset: $offset, query: $query,limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                date
                amount
            }
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const DELETE_PAYMENT = (gql: any) => gql`
    mutation deletePayment($id: ID!) {
        deletePayment(id: $id) {
            id
        }
    }
`;


// noinspection JSUnusedGlobalSymbols
export const SEARCH_MERCHANT_STORES = (gql: any) => gql`
    query searchMerchantStores($id: String!, $offset: String, $limit: Int, $sort: String) {
        searchMerchantStores(id: $id, offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                merchant           
                externalReference
                paymentName
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
                partnerName
            }
        }
    }
`;


//noinspection JSUnusedGlobalSymbols
export const FIND_STORES_BY_MERCHANT = (gql: any) => gql`
    query findStoresByMerchant($id: ID!) {
        findStoresByMerchant(merchantId: $id) {
            items {
                id
                name
                address
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_STORE = (gql: any) => gql`
    query getStore($id: ID!) {
        getStore(id: $id) {
            id
            name
            merchant
            externalReference
            createdAt
            updatedAt
            address
            location
            status
            tags
            siret
            paymentName
            statReceipts
            statMatches
            public
            partnerName
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_STORE = (gql: any) => gql`
    mutation updateStore($id: ID!, $data: UpdateStoreInput!) {
        updateStore(id: $id, data: $data) {
            id
            public
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_KPI = (gql: any) => gql`
query compound {
	getKpi {
		receiptCount
		paymentCount
		transactionCount
		payloadCount
		matchCount
		storeCount
	}
}
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_TOKEN = (gql: any) => gql`
    mutation createToken($data: CreateTokenInput!) {
        createToken(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_TOKEN = (gql: any) => gql`
    mutation deleteToken($id: ID!) {
        deleteToken(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_TOKENS = (gql: any) => gql`
    query findTokens($offset: String, $limit: Int) {
        findTokens(offset: $offset, limit: $limit) {
            items {
                id
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_TOKEN = (gql: any) => gql`
    query getToken($id: ID!) {
        getToken(id: $id) {
            id
            createdAt
            updatedAt
            status
            tags
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_TOKEN = (gql: any) => gql`
    mutation updateToken($id: ID!, $data: UpdateTokenInput!) {
        updateToken(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_TRANSACTION = (gql: any) => gql`
    mutation deleteTransaction($id: ID!) {
        deleteTransaction(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_TRANSACTIONS = (gql: any) => gql`
    query findTransactions($offset: String, $limit: Int) {
        findTransactions(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                amount
                bankName
                storeName
                consumer
                createdAt
                updatedAt
                status
                currency
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_TRANSACTIONS = (gql: any) => gql`
    query searchTransactions($offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchTransactions(offset: $offset, query: $query,limit: $limit, sort: $sort) {
            count
            cursor
            items {
                id
                amount
                bankName
                storeName
                consumer
                createdAt
                updatedAt
                status
                currency
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_BANK_TRANSACTIONS = (gql: any) => gql`
    query searchBankTransactions($id: String!, $offset: String, $query: SearchQueryInput, $limit: Int, $sort: String) {
        searchBankTransactions(id: $id, offset: $offset, query: $query, limit: $limit, sort: $sort) {
            count
            cursor
            items {
                id
                amount
                bankName
                storeName
                consumer
                createdAt
                updatedAt
                status
                currency
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_TRANSACTION = (gql: any) => gql`
    mutation updateTransaction($id: ID!, $data: UpdateTransactionInput!) {
        updateTransaction(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_TRANSACTION = (gql: any) => gql`
    query getTransaction($id: ID!) {
        getTransaction(id: $id) {
            id
            amount
            reference
            externalReference
            degradedReference
            createdAt
            updatedAt
            archivedAt
            matchedAt
            indexedDate
            bank
            bankName
            consumer
            currency
            date
            matchable
            payloadId
            receiptFormat
            status
            storeName
            tags
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_USER = (gql: any) => gql`
    mutation createUser($data: CreateUserInput!) {
        createUser(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_USER = (gql: any) => gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_USERS = (gql: any) => gql`
    query findUsers($offset: String, $limit: Int) {
        findUsers(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                role
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_USERS = (gql: any) => gql`
    query searchUsers($offset: String, $limit: Int, $sort: String) {
        searchUsers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                role
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_USER = (gql: any) => gql`
    query getUser($id: ID!) {
        getUser(id: $id) {
            id
            email
            firstName
            lastName
            lastConnexionDate
            createdAt
            updatedAt
            status
            tags
            role
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_USER = (gql: any) => gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
        updateUser(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_PAYLOADS = (gql: any) => gql`
    query findPayloads {
        findPayloads {
            items {
                id
                partner
                payload
                dbId
                date
                status
                report
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_PAYLOADS = (gql: any) => gql`
    query searchPayloads($offset: String, $limit: Int, $query: SearchQueryInput, $sort: String) {
        searchPayloads(offset: $offset, limit: $limit, query: $query, sort: $sort) {
            count
            cursor
            items {
                id
                partner
                payload
                dbId
                date
                status
                report
                receiptId
                transactionId
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_UPDATE_PAYLOADS = (gql: any) => gql`
    query findUpdatePayloads($offset: String, $limit: Int, $storeId: String) {
        findUpdatePayloads(offset: $offset, limit: $limit, storeId: $storeId) {
            count
            cursor
            items {
                id
                partner
                payload
                dbId
                date
                status
                report
                receiptId
                transactionId
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_PAYLOAD = (gql: any) => gql`
    mutation updatePayload($id: ID!, $data: PayloadInput!) {
        updatePayload(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_PAYLOAD = (gql: any) => gql`
    query getPayload($id: ID!) {
        getPayload(id: $id) {
            id
            partner
            dbId
            payload
            date
            status
            report
            receiptId
            transactionId
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_MATCH_JSON = (gql: any) => gql`
query getFile($query: String) {
    getFile(query: $query) {
        data
    }
}
`;

// noinspection JSUnusedGlobalSymbols
export const GET_PARTNERSPAYLOAD = (gql: any) => gql`
query getPayload($id: ID!) {
    getPayload(id: $id) {
        id
        partner
        dbId
        payload
        date
        status
        report
        receiptId
        transactionId
    }
}
`;

// noinspection JSUnusedGlobalSymbols
export const DELETE_PAYLOAD = (gql: any) => gql`
mutation deletePayload($id: ID!) {
    deletePayload(id: $id) {
        id
    }
}
`;

// noinspection JSUnusedGlobalSymbols
export const GET_PARTNERS_PAYLOADS = (gql: any) => gql`
    query getPayload($id: ID!) {
        getPayload(id: $id) {
            id
            partner
            payload
            transactionId
            receiptId
            date
            status
            report
        }
    }
 `;

 // noinspection JSUnusedGlobalSymbols
export const SEARCH_IN_TEXT = (gql: any) => gql`
    query searchInText($index: String ,$field:String,$value:String){
	      searchInText(index:$index ,field:$field,value:$value) {
        hits
    }
}
`;

export const FIND_MATCHING_HEALTH_STATUS = (gql: any) => gql`
    query findMatchingHealthStatus($currentMonthTimestamp: String ,$limit:Int){
        findMatchingHealthStatus(currentMonthTimestamp:$currentMonthTimestamp ,limit:$limit) {
        count
        cursor
        items {
            id
            createdAt
            updatedAt
            report
            date
            failedMatches
        }
    }
}
`;