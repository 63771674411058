const routes = [
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: "/reset/:token", screen: 'reset', secured: false},

    {path: '/', exact: false, screen: 'app', routes: [
        {path: '/', screen: 'home', exact: true},
        {path: '/', exact: false, screen: 'crud_app', routes: [
            /* {path: "/search/:id", screen: 'receipt'},
            {path: "/search", screen: 'search'}, */
            {path: "/searchtext", screen: 'search_text'},
            {path: "/banks/:bank/matches/:id", screen: 'bank_match'},
            {path: "/banks/:bank/matches/:id", screen: 'bank_match'},
            {path: "/banks/:bank/transactions/:id", screen: 'bank_transaction'},
            {path: "/banks/:id/matches", screen: 'bank_matches'},
            {path: "/banks/:id/transactions", screen: 'bank_transactions'},
            {path: "/banks/:id/edit", screen: 'edit_bank'},
            {path: "/banks/:id", screen: 'bank'},
            {path: "/banks", screen: 'banks'},
            {path: "/demobanks/:id", screen: 'demobank'},
            {path: "/demobanks", screen: 'demobanks'},
            {path: "/partner/new", screen: 'create_partner'},
            {path: "/bank/new", screen: 'create_bank'},
            {path: "/matches/:id", screen: 'match'},
            {path: "/matches", screen: 'matches'},
            {path: "/consumers/:id/edit", screen: 'edit_consumer'},
            {path: "/consumers/:id", screen: 'consumer'},
            {path: "/consumer/new", screen: 'create_consumer'},
            {path: "/consumers", screen: 'consumers'},
            {path: "/stores/:id/edit", screen: 'edit_store'},
            {path: "/stores/:id", screen: 'store'},
            {path: "/store/new", screen: 'create_store'},
            {path: "/stores", screen: 'stores'},
            {path: "/memberships/:id/edit", screen: 'edit_membership'},
            {path: "/memberships/:id", screen: 'membership'},
            {path: "/memberships", screen: 'memberships'},
            {path: "/membership/new", screen: 'create_membership'},
            {path: "/merchants/:merchant/matches/:id", screen: 'merchant_match'},
            {path: "/merchants/:merchant/receipt/:id", screen: 'merchant_receipt'},
            {path: "/merchants/:id/matches", screen: 'merchant_matches'},
            {path: "/merchants/:id/receipts", screen: 'merchant_receipts'},
            {path: "/merchants/:id/edit", screen: 'edit_merchant'},
            {path: "/merchants/:id", screen: 'merchant'},
            {path: "/merchants", screen: 'merchants'},
            {path: "/merchant/new", screen: 'create_merchant'},
            {path: "/organizations/:id/edit", screen: 'edit_organization'},
            {path: "/organizations/:id", screen: 'organization'},
            {path: "/organizations", screen: 'organizations'},
            {path: "/organization/new", screen: 'create_organization'},
            {path: "/payloads/:id/edit", screen: 'edit_payload'},
            {path: "/payloads/:id", screen: 'payload'},
            {path: "/payloads", screen: 'payloads'},
            {path: "/platforms/:id/edit", screen: 'edit_platform'},
            {path: "/platforms/:id", screen: 'platform'},
            {path: "/platforms", screen: 'platforms'},
            {path: "/platform/new", screen: 'create_platform'},
            {path: "/receipts/:id/edit", screen: 'edit_receipt'},
            {path: "/receipts/:id", screen: 'receipt'},
            {path: "/receipts", screen: 'receipts'},
            {path: "/tokens/:id/edit", screen: 'edit_token'},
            {path: "/tokens/:id", screen: 'token'},
            {path: "/token/new", screen: 'create_token'},
            {path: "/tokens", screen: 'tokens'},
            {path: "/hmac", screen: 'hmac'},
            {path: "/transactions/:id/edit", screen: 'edit_transaction'},
            {path: "/transactions/:id", screen: 'transaction'},
            {path: "/transactions", screen: 'transactions'},
            {path: "/payments/:id", screen: 'payment'},
            {path: "/payments", screen: 'payments'},
            {path: "/users/:id/edit_user_password", screen: 'edit_user_password'},
            {path: "/users/:id/edit", screen: 'edit_user'},
            {path: "/user/new", screen: 'create_user'},
            {path: "/users/:id", screen: 'user'},
            {path: "/users", screen: 'users'},
            {path: "/user/profile/edit", screen: 'edit_current_user'},
            {path: "/user/profile/edit_password", screen: 'edit_current_user_password'},
            {path: "/user/profile", screen: 'user_profile'},
            {path: '/', screen: 'home', exact: true},
            {path: '/partnerAccess', screen: 'PartnerAccess', exact: true},
            {path: '/export', screen: 'Export', exact: true},
            {path: '/matchinghealth', screen: 'MatchingHealth', exact: true},
            {screen: 'not_found', exact: false, secured: false},
        ]},
    ]},
];
export default routes