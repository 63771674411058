import {breadcrumb_item} from "@genstackio/react-admin-ui/lib/types";

function buildDynamicLabelItem(t: Function, i18nKey: string, target: string, id: string|undefined) {
    return ((data: any) => ({label: t(i18nKey, {id: data?.name || data?.email || id}), target})) as any;
}

export function breadcrumbs(name: string, {t, id}: {t: Function, history: any, id?: string}): breadcrumb_item[] {
    const items = [{label: t('screen_home_label'), target: '/'}];
    const demoBank = [{label: t('screen_home_label'), target: '/demobanks'}];

    switch (name) {
        case 'payments': return items.concat([
            {label: t('screen_payments_label'), target: '/payments'},
        ])
        case 'payment': return items.concat([
            {label: t('screen_payments_label'), target: '/payments'},
            {label: t('screen_payment_label', {id: id}), target: `/payments/${id}`},
        ])
        case 'consumers': return items.concat([
            {label: t('screen_consumers_label'), target: '/consumers'},
        ])
        case 'consumer': return items.concat([
            {label: t('screen_consumers_label'), target: '/consumers'},
            buildDynamicLabelItem(t, 'screen_consumer_label', `/consumers/${id}`, id),
        ])
        case 'users': return items.concat([
            {label: t('screen_users_label'), target: '/users'},
        ])
        case 'user': return items.concat([
            {label: t('screen_users_label'), target: '/users'},
            buildDynamicLabelItem(t, 'screen_user_label', `/users/${id}`, id),
        ])
        case 'receipts': return items.concat([
            {label: t('screen_receipts_label'), target: '/receipts'},
        ])
        case 'receipt': return items.concat([
            {label: t('screen_receipts_label'), target: '/receipts'},
            {label: t('screen_receipt_label', {id: id}), target: `/receipts/${id}`},
        ])
        case 'stores': return items.concat([
            {label: t('screen_stores_label'), target: '/stores'},
        ])
        case 'store': return items.concat([
            {label: t('screen_stores_label'), target: '/stores'},
            buildDynamicLabelItem(t, 'screen_store_label', `/stores/${id}`, id),
        ])

        case 'payloads': return items.concat([
            {label: t('screen_partners_payloads_label'), target: '/payloads'},
        ])
        case 'payload': return items.concat([
            {label: t('screen_partners_payloads_label'), target: '/payloads'},
            {label: t('screen_partner_payload_label', {id: id}), target: `/payloads/${id}`},
        ])

        case 'transactions': return items.concat([
            {label: t('screen_transactions_label'), target: '/transactions'},
        ])
        case 'transaction': return items.concat([
            {label: t('screen_transactions_label'), target: '/transactions'},
            {label: t('screen_transaction_label', {id: id}), target: `/transactions/${id}`},
        ])
        case 'matches': return items.concat([
            {label: t('screen_matches_label'), target: '/matches'},
        ])
        case 'match': return items.concat([
            {label: t('screen_matches_label'), target: '/matches'},
            {label: t('screen_match_label', {id: id}), target: `/matches/${id}`},
        ])
        case 'demobanks': return demoBank.concat([
            {label: t('screen_demobanks_label'), target: '/demobanks'},
        ])
        case 'demobank': return demoBank.concat([
            {label: t('screen_demobank_label', {id: id}), target: `/demobanks/${id}`},
        ])
        case 'banks': return items.concat([
            {label: t('screen_banks_label'), target: '/banks'},
        ])
        case 'bank': return items.concat([
            {label: t('screen_banks_label'), target: '/banks'},
            buildDynamicLabelItem(t, 'screen_bank_label', `/banks/${id}`, id),
        ])
        case 'platforms':  return items.concat([
            {label: t('screen_platforms_label'), target: '/platforms'},
        ])
        case 'platform': return items.concat([
            {label: t('screen_platforms_label'), target: '/platforms'},
            buildDynamicLabelItem(t, 'screen_platform_label', `/platforms/${id}`, id),
        ])
        case 'organizations': return items.concat([
            {label: t('screen_organizations_label'), target: '/organizations'},
        ])
        case 'organization': return items.concat([
            {label: t('screen_organizations_label'), target: '/organizations'},
            buildDynamicLabelItem(t, 'screen_organization_label', `/organizations/${id}`, id),
        ])
        case 'merchants': return items.concat([
            {label: t('screen_merchants_label'), target: '/merchants'},
        ])
        case 'merchant': return items.concat([
            {label: t('screen_merchants_label'), target: '/merchants'},
            buildDynamicLabelItem(t, 'screen_merchant_label', `/merchants/${id}`, id),
        ])
        case 'memberships': return items.concat([
            {label: t('screen_memberships_label'), target: '/memberships'},
        ])
        case 'membership': return items.concat([
            {label: t('screen_memberships_label'), target: '/memberships'},
            {label: t('screen_membership_label', {id: id}), target: `/memberships/${id}`},
        ])
        case 'tokens': return items.concat([
            {label: t('screen_tokens_label'), target: '/tokens'},
        ])
        case 'token': return items.concat([
            {label: t('screen_tokens_label'), target: '/tokens'},
            buildDynamicLabelItem(t, 'screen_token_label', `/tokens/${id}`, id),
        ])
        case 'partnerAccess': return items.concat([
            {label: 'partnerAccess', target: '/partnerAccess'},
        ])
        default: return [
            {label: t('screen_home_label'), target: '/'},
        ];
    }
}

export default breadcrumbs;