import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faMoon } from '@fortawesome/free-regular-svg-icons/faMoon';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons/faCommentAlt';
import { faCommentAlt as faCommentAltSolid } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons/faBell';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons/faCartArrowDown';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faLandmark } from '@fortawesome/free-solid-svg-icons/faLandmark';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons/faStoreAlt';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faStore } from '@fortawesome/free-solid-svg-icons/faStore';
import { faShop } from '@fortawesome/free-solid-svg-icons/faShop';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faEquals } from '@fortawesome/free-solid-svg-icons/faEquals';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faMapPin } from '@fortawesome/free-solid-svg-icons/faMapPin';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons/faPiggyBank';

const icons = {
    fa: [
        faCheckSquare,
        faMoon,
        faLightbulb,
        faStar,
        faStarSolid,
        faExpand,
        faCommentAlt,
        faCommentAltSolid,
        faBell,
        faBellSolid,
        faShoppingCart,
        faCartArrowDown,
        faSearch,
        faUsers,
        faReceipt,
        faChartLine,
        faLandmark,
        faStoreAlt,
        faBuilding,
        faChartPie,
        faBalanceScale,
        faKey,
        faPlus,
        faInfo,
        faEye,
        faEdit,
        faTrashAlt,
        faLink,
        faAddressCard,
        faExchangeAlt,
        faMapPin,
        faFacebookSquare,
        faStore,
        faShop,
        faFile,
        faCreditCard,
        faEquals,
        faFolderOpen,
        faPiggyBank,
    ],
};

export default icons;
